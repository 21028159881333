<template>
	<div class="tree">
		<div id="graph"></div>
		<div class="graph-zoom">
			<div class="range">
			<button id="minus" class="circle" data-quantity="minus" v-on:click="zoomOut()">
				<i class="fa fa-minus"></i>
			</button>
			<input id="graphZoom" name="graphZoom" type="range" min="0" max="100" v-model="graphZoom" @input="changeRange" />
			<button id="plus" class="circle" data-quantity="plus" v-on:click="zoomIn()">
				<i class="fa fa-plus"></i>
			</button>
			</div>
			<div class="zoom-info">
				<output for="graphZoom" id="output"><span>Zoom</span> <span>{{ graphZoom + "%" }}</span></output>
				<button class="reset-zoom" v-on:click="resetZoom()">Reset</button>
			</div>
		</div>
		<!-- end zoom -->
	</div>
	<!-- end tree -->
</template>

<script>
import {mapGetters} from "vuex"
import ForceGraph from "force-graph"
import * as d3 from "d3"
//import {exampleContentTree} from '../../services/content-nodes'


let main = null
let sidebarWrapper = null
let nodeForm = null
let nodeOverview = null
let range = null

//1% of range
let min = 1.4
let max = 30
let step = (max - min) / 100

export default {
	data() {
		return {
			graphZoom: 8,
			Graph: ForceGraph(),
		}
	},
	computed: {
		currentNode() {
			return this.getCurrentNode
		},
		getRootNode() {
			return this.rootNode
		},
		...mapGetters("views", ["getSidebarOpenState"]),
		...mapGetters("client", ["getClientContentTree", "getContentTreeFilters", "getFilteredNodes", "getCurrentNode", "getClientData", "getHighlightedNode"]),
	},
	watch: {
		async currentNode() {
			//console.log("current node changed")
			// this.centerOnNode()
		},
	},
	components: {},
	props: ["clientSlug"],
	methods: {
		async fitToScreen() {
			// console.log("fitting to screen")
			this.Graph.zoomToFit(1000, 20)
		},
    closeAddNode() {
			if (nodeForm.classList.contains("form-open")) {
				main.classList.remove("node-form-open")
				main.classList.add("node-form-closed")
				nodeForm.classList.remove("form-open")
				nodeForm.classList.add("form-closed")
			}
		},
		async centerOnNode() {
			this.openNodeOverview()
			let forceGraphNodes = this.Graph.graphData().nodes
			let selectedNode = await this.getCurrentNode
			let nodeToFocus = forceGraphNodes.find((node) => selectedNode.id == node.id)
			console.log(nodeToFocus.x)
			this.Graph.centerAt(nodeToFocus.x, nodeToFocus.y, 1000)
			// this.Graph.zoom(12, 2000)
		},
		selectNode(nodeId) {
			this.$emit("selectNode")
			this.closeSidebar()
			this.openNodeOverview()
            // console.log(nodeId)
			this.$store.dispatch("client/selectNode", nodeId)
      this.closeAddNode();
		},
		highlightNode(nodeId) {
			this.$emit("highlightNode")
			this.$store.dispatch("client/highlightNode", nodeId)
		},
		clearHighlightNode() {
			this.$emit("clearHighlightNode")
			this.$store.dispatch("client/clearHighlightNode")
		},
		openAddNode() {
			if (nodeForm.classList.contains("form-closed")) {
				main.classList.remove("node-form-closed")
				main.classList.add("node-form-open")
				nodeForm.classList.remove("form-closed")
				nodeForm.classList.add("form-open")
			}
			this.closeNodeOverview()
		},
		closeSidebar() {
			this.$store.dispatch("views/closeSidebar")
			if (!this.getSidebarOpenState) {
				sidebarWrapper.classList.remove("open")
				sidebarWrapper.classList.add("closed")
			}
		},
		closeNodeOverview() {
			if (nodeOverview.classList.contains("overview-open")) {
				main.classList.remove("overview-open")
				main.classList.add("overview-closed")
				nodeOverview.classList.remove("overview-open")
				nodeOverview.classList.remove("overview-closed")
			}
		},
		openNodeOverview() {
			if (nodeOverview.classList.contains("overview-closed")) {
				main.classList.add("overview-open")
				main.classList.remove("overview-closed")
				nodeOverview.classList.add("overview-open")
				nodeOverview.classList.add("overview-closed")
			}
		},
		zoomIn() {
			const currentValue = Number(range.value)
			if (currentValue >= 0 && currentValue != 100) {
				range.value = currentValue + 1
				this.graphZoom = currentValue + 1
				const zoomLevel = Number(this.graphZoom * step + min)
				this.Graph.zoom(zoomLevel, 100)
			}
		},
		zoomOut() {
			const currentValue = Number(range.value)
			if (currentValue <= 100 && currentValue != 0) {
				range.value = currentValue - 1
				this.graphZoom = currentValue - 1
				const zoomLevel = Number(this.graphZoom * step + min)
				this.Graph.zoom(zoomLevel, 100)
			}
		},
		resetZoom() {
			this.Graph.zoomToFit(500, 20)
		},
		changeRange() {
			const currentValue = Number(range.value)
			this.graphZoom = currentValue
			const zoomLevel = Number(this.graphZoom * step + min)
			this.Graph.zoom(zoomLevel, 100)
		},
		numberWithCommas(x) {
			return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		},
        /**
         * =================================================================================================
         * This is the renderContentTree method
         * It has everything to do with how the content tree is rendered on each frame
         * =================================================================================================
         */
        async renderContentTree() {
			//console.log("rendering tree")
			let contentTree = JSON.parse(JSON.stringify(this.getClientContentTree))
			// let contentTree = exampleContentTree;
			let NODE_REL_SIZE = 2
			let treeNodes = contentTree.nodes
			let treeLinks = contentTree.links

			const treeGraphData = {
				nodes: treeNodes,
				links: treeLinks,
			}

			//set circle sizes
			let circleRadius = 8
			let rootRadius = 22
			let level1Radius = 14.36
			let level2Radius = 8.25
			let level3Radius = 4.74

			function wrapText(context, text, x, y, maxWidth, lineHeight) {
				var words = text.split(" ")
				var line = ""
				for (var n = 0; n < words.length; n++) {
					var testLine = line + words[n] + " "
					var metrics = context.measureText(testLine)
					var testWidth = metrics.width
					if (testWidth > maxWidth) {
						context.fillText(line, x, y)
						line = words[n] + " "
						y += lineHeight
					} else {
						line = testLine
					}
				}
				context.fillText(line, x, y)
			}
			function inverseProportion(base, scale) {
				let main = base - scale / base

				if (main <= 1.2) {
					return 1.2 - scale / 100
				} else {
					return main
				}
			}

			function numberWithCommas(x) {
				return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			}

			//set node background
			let nodebg = new Image()
			nodebg.src = require("../../assets/img/node.svg")

			let lightNodebg = new Image()
			lightNodebg.src = require("../../assets/img/lightNode.svg")

			let curve1 = new Image()
			curve1.src = require("../../assets/img/links/curve-1.svg")

			let curve21 = new Image()
			curve21.src = require("../../assets/img/links/curve-2-1.svg")

			let curve22 = new Image()
			curve22.src = require("../../assets/img/links/curve-2-2.svg")

			let curve32 = new Image()
			curve32.src = require("../../assets/img/links/curve-3-2.svg")

			let curve33 = new Image()
			curve33.src = require("../../assets/img/links/curve-3-3.svg")

			let curve43 = new Image()
			curve43.src = require("../../assets/img/links/curve-4-3.svg")

			let curve44 = new Image()
			curve44.src = require("../../assets/img/links/curve-4-4.svg")

			let line1 = new Image()
			line1.src = require("../../assets/img/links/line-1.svg")

			//generate the graph
			this.Graph(document.getElementById("graph"))
				.graphData(treeGraphData)
				.nodeId("id")
				.nodeVal(3)
				.nodeLabel("label")
				.nodeAutoColorBy("group")
				.linkSource("source")
				.linkTarget("target")
				.zoom(Number(step * 8 + min))
				.minZoom(min)
				.maxZoom(max)
				.linkWidth(2)
				.nodeAutoColorBy("group")
				//.enablePointerInteraction(true)
				// .enableNodeDrag(false)
				.nodeRelSize(NODE_REL_SIZE)
				.d3Force(
					"collision",
					d3.forceCollide((node) => Math.sqrt(500 / (node.level + 10)) * NODE_REL_SIZE)
				)
				.d3VelocityDecay(0.1)
				.autoPauseRedraw(false)
				.d3AlphaDecay(0.02)
				.d3Force("center", null)
				.d3Force("charge", d3.forceManyBody().strength(-20))
				.d3Force(
					"link",
					d3.forceLink().distance(function (link) {
						// console.log(link.target.level)
						if (link.source.level === 0) {
							return 60
						} else if (link.source.level === 1) {
							return 40
						} else if (link.source.level === 2) {
							return 20
						} else if (link.source.level >= 3) {
							return 15
						}
					})
				)
				.onZoom((k) => {
					//update the zoom range slider value
					const value = Number((k.k - min) / (max - min)) * 100
					this.graphZoom = Math.round(value)
				})
				.onNodeClick((node) => {
					let nodeIsFiltered = this.getFilteredNodes.find((filteredNode) => node.id === filteredNode.id)
					let filtersSelected = Object.keys(this.getContentTreeFilters).length > 0
					// zoom on node
					if (nodeIsFiltered || !filtersSelected) {
						
						this.selectNode(node.id)

						let currentNodeLevel = parseInt(this.getCurrentNode.level)

						// let desiredZoom = 12
						// let desiredZooms = [20,24,28];
						// desiredZoom = desiredZooms[currentNodeLevel - 1];
						// if(currentNodeLevel > desiredZooms.length){
						// 	desiredZoom = desiredZooms[desiredZooms.length - 1];
						// }
						// 				this.Graph.zoom(desiredZoom, 1000)
							// console.log('letitsnow',desiredZoom);
						// switch (currentNodeLevel) {
						// 	case currentNodeLevel = 1:
						// 		desiredZoom = 20
						// 		break;

						// 	case currentNodeLevel = 2:
						// 		desiredZoom = 24
						// 		break;
						// 	case currentNodeLevel >= 3:
						// 		desiredZoom = 28
						// 		break;

						// }
						// console.log(desiredZoom)
						if (currentNodeLevel == 1) {
							this.Graph.zoom(18, 1000)
							// console.log(currentNodeLevel)
						} else if (currentNodeLevel == 2) {
							this.Graph.zoom(22, 1000)
							// console.log(currentNodeLevel)
						}
						else if (currentNodeLevel >= 3) {
							this.Graph.zoom(26, 1000)
							// console.log(currentNodeLevel)
						}					
						else {
							this.Graph.zoom(12, 1000)
							// console.log(currentNodeLevel)
						}

						// console.log(currentNodeLevel)
					
						this.centerOnNode()
					}
				})
				.onNodeHover((node) => {
					if (node) {
						if (node.id) {
							// console.log(node.id)
							this.highlightNode(node.id)
							// this.Graph.d3ReheatSimulation()
						}
					} else {
						this.clearHighlightNode()
					}
				})
				.nodeCanvasObject((node, ctx, globalScale) => {
					let lineThickness = 1.5
					// const label = node.name
					// console.log(node.fieldGroups)
					let nodeTitle = node.fieldGroups.title.fields.title.value
					let nodeId = node.name
					let nodeReachText = node.level <= 1 ? node.accumulativeReach : node.fieldGroups.keyword.fields.reach.value;
					const fontSize = 14 / globalScale
					ctx.font = `${fontSize}px Gilroy`
					//const bckgDimensions = [textWidth, fontSize].map((n) => n + fontSize * 5)
					const nodeStatus = node.fieldGroups.status.fields.status.value.toLowerCase() //status value path from data

					//update the node stroke colour using the node status
					const pink = "255,80,162"
					const blue = "42,187,238"
					const orange = "255,131,68"
					const green = "24,178,140"
					const grey = "79,81,112"
					const white = "220,220,241"
					let rgbValue = grey

					switch (nodeStatus) {
						case "title created":
							rgbValue = pink
							break
						case "assigned":
							rgbValue = blue
							break
						case "pending review":
							rgbValue = orange
							break
						case "published":
							rgbValue = green
							break
						case "archived":
							rgbValue = grey
							break
					}

					//node filtering
					let strokeColor = `rgba(${rgbValue},1)`
					let textFillStyle = `rgba(255,255,255,1)`
					let nodeIsFiltered = this.getFilteredNodes.find((filteredNode) => node.id === filteredNode.id)
					let filtersSelected = Object.keys(this.getContentTreeFilters).length > 0
					let nodeIsCurrentNode = node.id === this.getCurrentNode.id
					let nodeIsHighlightedNode = node.id === this.getHighlightedNode.id

					if (!nodeIsFiltered && filtersSelected) {
						// ctx.globalCompositeOperation = "lighter";
						// ctx.globalAlpha = 0.5
						strokeColor = `rgba(${rgbValue}, 0.1)`
						textFillStyle = `rgba(255,255,255, 0.1)`

						if (nodeIsCurrentNode) {
							strokeColor = `rgba(${rgbValue}, 0.3)`
							textFillStyle = `rgba(255,255,255, 0.3)`
						}
					}

					//set node sizes based on level
					if (node.parent === "root") {
						//center root node
						node.fx = node.x
						node.fy = node.y
						circleRadius = rootRadius
						lineThickness = 2.2
						strokeColor = `rgba(${grey}, 1)`
						nodeTitle = this.getClientData.clientName
					} else if (node.level === 1) {
						lineThickness = 1.5
						circleRadius = level1Radius
						strokeColor = `rgba(${grey}, 1)`
					} else if (node.level === 2) {
						lineThickness = 1.2
						circleRadius = level2Radius
					} else if (node.level > 2) {
						lineThickness = 1
						circleRadius = level3Radius
					}

					let shadowColor = null
					let shadowBlur = 0
					//let icon = "\uf05a" //info icon
					//let icon = "\uf055" //plus icon

					//current clicked node
					if (nodeIsCurrentNode) {
						shadowColor = strokeColor
						shadowBlur = 35
						this.Graph.centerAt(node.x - 15, node.y, 10)
					}

					//hovered node
					if (nodeIsHighlightedNode || nodeIsCurrentNode) {
						if (node.parent === "root" || node.level === 1 ) {
							shadowColor = `rgba(${white}, 1)`
							shadowBlur = 35
						} else {
							shadowColor = strokeColor
							shadowBlur = 35
						}
						
					} else {
						shadowColor = null
						shadowBlur = 0
					}
					
						ctx.save()
					//apply glow to current node
					ctx.shadowColor = shadowColor
					ctx.shadowBlur = shadowBlur
					//set img background

					// ctx.save();

					if (!nodeIsFiltered && filtersSelected) {
						// Draw the lighter image instead
						ctx.drawImage(lightNodebg, node.x - circleRadius, node.y - circleRadius, circleRadius * 2, circleRadius * 2)
					} else {
						ctx.drawImage(nodebg, node.x - circleRadius, node.y - circleRadius, circleRadius * 2, circleRadius * 2)
					}

					ctx.strokeStyle = strokeColor

					ctx.beginPath()
					ctx.lineWidth = lineThickness / (globalScale / 2)

					if (this.Graph.zoom() >= 7) {
						ctx.lineWidth = lineThickness / (globalScale / 4)
					} else {
						ctx.lineWidth = lineThickness / (globalScale / 2)
					}

					ctx.arc(node.x, node.y, circleRadius, 0, 2 * Math.PI, false)
					ctx.stroke()

				
					
					ctx.restore();

					//set the node text params
					// ctx.save()
					// //dotsAlign = circleRadius - 2
					// ctx.textAlign = "center"
					// ctx.textBaseline = "middle"
					// ctx.fillStyle = textFillStyle
					// if (node.level > 1) {
					// 	if (this.Graph.zoom() >= 7) {
					// 		ctx.fillText(label, node.x, node.y - (circleRadius - 3))
					// 	}
					// } else {
					// 	ctx.fillText(label, node.x, node.y - (circleRadius - 3))
					// }
					// /*if (this.Graph.zoom() >= 7) {
					// 	if (nodeIsCurrentNode && node.level > 0) {
					// 		ctx.font = '500 3px "Font Awesome 5 Pro"'
					// 		ctx.fillText("\uf141", node.x, node.y + dotsAlign)
					// 	}
					// }*/
					// ctx.restore()

					ctx.textAlign = "center"
					ctx.font = `400 ${fontSize}px Sans-Serif`
					ctx.fillStyle = textFillStyle

					if (this.Graph.zoom() >= 3) {
						ctx.fillText(nodeId, node.x, node.y - (circleRadius - inverseProportion(5, globalScale)))
					}

					//set the node title params
					// ctx.save()
					ctx.textAlign = "center"
					ctx.textBaseline = "middle"
					ctx.font = `600 ${fontSize}px Sans-Serif`
					ctx.fillStyle = textFillStyle
					if (node.level <= 1) {
						// ctx.fillText(nodeTitle, node.x, node.y + 1.5, 10)
						if (this.Graph.zoom() >= 4) {
							wrapText(ctx, nodeTitle, node.x, node.y - 2, circleRadius * 2 - 2, 0)
							
						}
						else {
							wrapText(ctx, nodeTitle, node.x, node.y - inverseProportion(4, globalScale), circleRadius * 2 - 2, inverseProportion(8, globalScale))
						}
					} else {
						if (this.Graph.zoom() >= 7) {
							if (node.level <= 2) {
								// ctx.fillText(nodeTitle, node.x, node.y + 1.5, 10)
								wrapText(ctx, nodeTitle, node.x, node.y - inverseProportion(4.2, globalScale), circleRadius * 2 - 2, inverseProportion(4.2, globalScale))
							}
						}
						if (this.Graph.zoom() >= 10) {
							wrapText(ctx, nodeTitle, node.x, node.y - inverseProportion(4.2, globalScale), circleRadius * 2 - 2, inverseProportion(4.2, globalScale))
						}
					}

					ctx.textAlign = "bottom"
					ctx.font = `400 ${fontSize}px Sans-Serif`
					ctx.fillStyle = textFillStyle
					if (this.Graph.zoom() >= 3) {
						if (node.level <= 1) {
							ctx.fillText(numberWithCommas(nodeReachText), node.x, node.y + (circleRadius - inverseProportion(5, globalScale)))
						}
					}
					if (this.Graph.zoom() >= 10) {
						if (node.level <= 2) {
							ctx.fillText(numberWithCommas(nodeReachText), node.x, node.y + (circleRadius - inverseProportion(5, globalScale)))
						}
					}

					if (this.Graph.zoom() >= 12) {
						ctx.fillText(numberWithCommas(nodeReachText), node.x, node.y + (circleRadius - inverseProportion(5, globalScale)))
					}
				

					//draw icons
					/*ctx.font = '900 3px "Font Awesome 5 Pro"'
					ctx.fillStyle = textFillStyle
					ctx.shadowBlur = 0
					ctx.strokeStyle = "black"
					ctx.lineWidth = 0.5
					ctx.fillStyle = "white"
					var xCoordinate = (circleRadius - 1) * Math.cos(2 * Math.PI)
					var yCoordinate = (circleRadius - 1) * Math.sin(2 * Math.PI)
					if (this.Graph.zoom() >= 7) {
						if (nodeIsCurrentNode && node.level > 0) {
							ctx.strokeText(icon, node.x + xCoordinate, node.y - yCoordinate)
							ctx.fillText(icon, node.x + xCoordinate, node.y - yCoordinate)
						}
					}*/
				})
				.linkCanvasObject((link, ctx) => {
					const start = link.source
					const end = link.target
					const l = Math.sqrt(Math.pow(end.x - start.x, 2) + Math.pow(end.y - start.y, 2)) // line length
					const a = Math.atan2(end.y - start.y, end.x - start.x) // line angle
					const angleOffset = (90 * Math.PI) / 180
					ctx.save()
					if (start.level === 0) {
						ctx.save()
						ctx.translate(start.x, start.y)
						ctx.rotate(a - angleOffset)
						ctx.drawImage(curve1, -20, 9, 40, 40)
						ctx.restore()

						ctx.save()
						ctx.translate(start.x, start.y)
						ctx.rotate(a - angleOffset)
						ctx.drawImage(line1, -3.35, 0, 6.7, l)
						ctx.restore()
					}
					if (end.level === 1) {
						ctx.save()
						ctx.translate(end.x, end.y)
						ctx.rotate(a + angleOffset)
						ctx.drawImage(curve21, -12.36, 7, 24.72, 24.72)
						ctx.restore()
					}
					if (start.level === 1) {
						ctx.save()
						ctx.translate(start.x, start.y)
						ctx.rotate(a - angleOffset)
						ctx.drawImage(curve22, -12.36, 7, 24.72, 24.72)
						ctx.restore()

						ctx.save()
						ctx.translate(start.x, start.y)
						ctx.rotate(a - angleOffset)
						ctx.drawImage(line1, -2.09, 0, 4.18, l)
						ctx.restore()
					}

					if (end.level === 2) {
						ctx.save()
						ctx.translate(end.x, end.y)
						ctx.rotate(a + angleOffset)
						ctx.drawImage(curve32, -6.25, 5.2, 12.5, 12.5)
						ctx.restore()
					}
					if (start.level === 2) {
						ctx.save()
						ctx.translate(start.x, start.y)
						ctx.rotate(a - angleOffset)
						ctx.drawImage(curve33, -6.25, 5.2, 12.5, 12.5)
						ctx.restore()

						ctx.save()
						ctx.translate(start.x, start.y)
						ctx.rotate(a - angleOffset)
						ctx.drawImage(line1, -0.95, 0, 2.055, l)
						ctx.restore()
					}
					if (end.level === 3) {
						ctx.save()
						ctx.translate(end.x, end.y)
						ctx.rotate(a + angleOffset)
						ctx.drawImage(curve43, -2.74, 3.7, 5.5, 5.5)
						ctx.restore()
					}
					if (start.level >= 3) {
						ctx.save()
						ctx.translate(start.x, start.y)
						ctx.rotate(a - angleOffset)
						ctx.drawImage(curve44, -2.74, 3.6, 5.5, 5.5)
						ctx.restore()

						ctx.save()
						ctx.translate(start.x, start.y)
						ctx.rotate(a - angleOffset)
						ctx.drawImage(line1, -0.595, 0, 1.19, l)
						ctx.restore()
					}
					if (end.level >= 4) {
						ctx.save()
						ctx.translate(end.x, end.y)
						ctx.rotate(a + angleOffset)
						ctx.drawImage(curve44, -2.74, 3.6, 5.5, 5.5)
						ctx.restore()
					}
					
				})
				.nodePointerAreaPaint((node, color, ctx) => {
					if (node.parent === "root") {
						circleRadius = rootRadius
					} else if (node.level === 1) {
						circleRadius = level1Radius
					} else if (node.level === 2) {
						circleRadius = level2Radius
					} else if (node.level > 2) {
						circleRadius = level3Radius
					}
					ctx.fillStyle = color
					ctx.beginPath()
					ctx.arc(node.x, node.y, circleRadius + 1.75, 0, 2 * Math.PI, false)
					ctx.fill()
				})

			//set 100% width to canvas
			const canvas = document.querySelector("canvas")
			canvas.style.width = "100%"
			canvas.style.height = "100%"

			window.addEventListener("resize", () => {
				canvas.style.width = "100%"
				canvas.style.height = "100%"
			})
		},
		
	},
	mounted() {
		main = document.getElementById("main")
		sidebarWrapper = document.getElementById("sidebarWrapper")
		nodeForm = document.getElementById("add-node-form")
		nodeOverview = document.getElementById("node-overview")
		range = document.getElementById("graphZoom")

		// If there is a nodeId query parameter in the URL set it as the currentNode
		if (this.$route.query?.node) {
			if (this.getClientContentTree.nodeIds.find((nodeId) => nodeId === this.$route.query?.node)) {
				this.selectNode(this.$route.query.node)
			}
		}

		setTimeout(() => {
			// If the no node in the tree is selected
			if (!this.getClientContentTree.nodeIds.find((nodeId) => nodeId === this.getCurrentNode.id)) {
				// Fit to screen
				if (this.getClientContentTree.nodeIds.length > 8) {
					this.fitToScreen()
				}
				
			} else {
				let currentNodeLevel = this.getCurrentNode.level
				if (currentNodeLevel > 1) {
					this.Graph.zoom(20, 1000)
				} else {
					this.Graph.zoom(12, 1000)
				}
			}
		}, 750)
		if (document.hasFocus()) {
			this.renderContentTree()
		}
	},
}
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/views/contentTree.scss";
</style>
